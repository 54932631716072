import type { GenericAbortSignal } from "axios";
import type { AppUserDTO2, LoanDto, LoanPhaseCategoryType, LoanRoleDto, SherpaEntityType } from "src/backend";
import type { LoanStatus, LoanTemplateType } from "src/constants/loan";

import { ValueOf } from "./common";
import { AppUserDTO2Extended } from "./user";

export interface LoanDtoExtended extends Omit<LoanDto, 'loanRoles'> {
    closeDate: string,
    loanProgress: number,
    closeDateDistance,
    closeDateYearFormatted: string,
    createdDateYearFormatted: string,
    closeDateFormatted: string,
    createdDateFormatted: string,
    closeDateIsPassed: boolean,
    templateProjectName: string,
    lastModifiedFormatted: string,
    lastModifiedByUser: AppUserDTO2Extended,
    loanRoles: ExtendedLoanRole[]
}
interface ExtendedLoanRole extends Omit<LoanDto['loanRoles'][0], 'user'> {
    user: AppUserDTO2Extended
}

export type ILoanStatus = keyof typeof LoanStatus;

export type LoanType = "UNSECURED" |
    "LINE_OF_CREDIT" |
    "CASH_SECURED" |
    "INVENTORY" |
    "ACCOUNTS_RECEIVABLE_CONTRACTS" |
    "EQUIPMENT" |
    "RENTAL_PROPERTY" |
    "LAND_DEVELOPMENT" |
    "CONSTRUCTION_COMMERCIAL" |
    "CONSTRUCTION_1_TO_4" |
    "REAL_ESTATE_PERMANENT" |
    "AGRICULTURAL" |
    "START_UP" |
    "ACQUISITION" |
    "SBA";

export type Loan = LoanDto & {
    closeDateFormatted?: string,
    closeDateYearFormatted?: string,
    createdDateYearFormatted?: string,
    closeDateIsPassed?: boolean,
    loanProgress?: number,
    closeDateDistance?: string
    createdDateFormatted?: string,
};

export interface LoanUserDigest {
    html: string;
    text: string;
}

export interface LoanTemplate extends Loan {

}

export type TLoanTemplateType = ValueOf<typeof LoanTemplateType>;

export interface LoanRole extends LoanRoleDto {
    totalProgress?: number;
    answerProgress?: number;
    acceptProgress?: number;
    sendInvite?: boolean;
    isLeader?: boolean;
    user: AppUserDTO2;
    templateId?: string;
}

export interface EntityMlaFields {
    id: string
    internalReviewValueForMla: string
    repairCost: string
    lenderPolicyLtv: string
    afterLeaseValue: string
    constructionCost: string
    appraisedValue: string
    purchasePrice: string
    lotPurchasePrice: string
    afterRepairValue: string
    bookValue: string
    asCompletedValue: string
    value: string
    bookValueDiscount: string
    estimatedValue: string
    totalCompletionCost: string
    type: SherpaEntityType
}

export enum LoanSortBy {
    newest = "newest",
    oldest = "oldest",
}

export enum LoanDisplayView {
    grid = "grid",
    list = "list",
}

export type LoanTableColumnIds = 'actualLoanAmount' | "amortizationInMonths" | 'loanTermInMonths' | 'loanID' |
    'loanType' | 'dateCreated' | 'loanStatus' | 'lenders' | 'applicants' |
    'downPayment' | 'amortization' | 'loanProgress' | 'requestedLoanAmount' |
    'downPaymentAmount' | 'downPaymentPercentage' | 'downPaymentSource' |
    'loanOriginationPoints' | 'loanOriginationFees' | 'loanOtherFees' | 'index' | 'margin' |
    'projectName' | 'projectCosts' | 'purchasePrice' | 'totalCosts' | 'pmla' | 'ltv' |
    'ltc' | 'apr' | 'actualLoanTerm' | 'closeDate' | 'loanTermInYears' | 'phaseCategory';

export interface LoanTableColumn {
    id: LoanTableColumnIds;
    isComponent: boolean;
    label: string;
    value: string | number;
    formattedValue?: string;
    sortable: boolean;
    numeric: boolean;
    align?: 'left' | 'right' | 'center';
}

export type LoansRequest = {
    role?: ViewType;
    phaseCategory?: LoanPhaseCategoryType;
    phaseId?: string;
    page?: number;
    size?: number;
    sort?: string;
    signal?: GenericAbortSignal;
    // force request to execute even with pending
    force?: boolean;
    clearPendingRequest?: boolean;
}

export type ViewType = "PRINCIPAL" | "LENDER" | "ANONYMOUS" | "CONTACT"