import { Draggable, Droppable } from "@hello-pangea/dnd";
import { forwardRef } from "react";
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso'
import { LoanPhaseDto } from 'src/backend';
import { Route } from "src/constants/routing";
import { Loan } from 'src/types/loan';
import { classNames } from "src/utils/tailwind-class-names";

import { NextLinkComposed } from '../common/next-link-composed';
import { LoanCard } from '../loans/loan-card';
import { LoansKanbanCardSkelton } from '../loans-kanban/loans-kanban-card-skeleton';

interface LoansColumnProps {
    isDragEnabled?: boolean;
    loans: Loan[];
    isLoading: boolean;
    loanPhase: LoanPhaseDto;
    className?: string;
    filterQuery?: string;
    isFocused?: boolean;
    onPinLoan?: (loanId: string) => void;
    onSendMessageClick: (loanId: string) => void;
}

const GridList = forwardRef<HTMLDivElement>((props: React.HTMLAttributes<HTMLDivElement>, ref) => (
    <div
        ref={ref}
        {...props}
        style={{
            display: "flex",
            flexWrap: "wrap",
            ...props.style,
        }}
    >
        {props.children}
    </div>
));

GridList.displayName = "GridList";

const GridItem = ({ children, ...props }) => (
    <div
        {...props}
        className="w-1/3 p-4 xl:w-1/4 flex items-stretch flex-col"
    >
        {children}
    </div>
)
const gridComponents = {
    List: GridList,
    Item: GridItem,
}

const ListItem = forwardRef<HTMLDivElement>((props: React.HTMLAttributes<HTMLDivElement>, ref) => (
    <div
        ref={ref}
        {...props}
        className={classNames(
            props.className,
            'px-4 py-2'
        )}
    >
        {props.children}
    </div>
));

ListItem.displayName = "ListItem";

const listComponents = {
    Item: ListItem,
};


const getItemSx = (draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // styles we need to apply on draggables
    ...draggableStyle
});


export const LoanKanbanPhase = (props: LoansColumnProps) => {
    return (<Droppable
        droppableId={props.loanPhase.id}
        isDropDisabled={!props.isDragEnabled}>
        {(provided, droppableSnapshot) => (<div
            key={props.loanPhase.id}
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classNames(
                props.className,
                'h-full MuiPaper-root shrink-0  overflow-hidden border-t-none',
                {
                    'bg-black-10': droppableSnapshot.isDraggingOver,
                }
            )}

        >
            {props.isFocused && <VirtuosoGrid
                className={classNames(
                    props.className,
                    'scrollbar-stable w-full h-full bg-gray-50 transition-colors shrink-0 flex overflow-x-hidden mb-4 border-t-none',
                    {
                    }
                )}

                // fixedItemHeight={260}
                // @ts-expect-error
                components={gridComponents}
                totalCount={props.loans.length}
                // increaseViewportBy={200}
                itemContent={index => <Draggable
                    isDragDisabled={!props.isDragEnabled}
                    key={props.loans[index].id}
                    draggableId={props.loans[index].id}
                    index={index}>
                    {(provided, draggableSnapshot) => (
                        <div
                            className='loan-card-container w-full w-full  flex-1 relative'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...getItemSx(provided.draggableProps.style),
                            }}>
                            <NextLinkComposed
                                className='w-full'
                                to={{ pathname: Route.SINGLE_LOAN, query: { loanId: props.loans[index].id } }}
                            >
                                <LoanCard
                                    onPin={props.onPinLoan}
                                    highlightText={props.filterQuery}
                                    loan={props.loans[index]}
                                    onSendMessageClick={props.onSendMessageClick}
                                />
                            </NextLinkComposed>
                        </div>
                    )}
                </Draggable>
                }


            />}
            {!props.isFocused && <Virtuoso
                // @ts-expect-error
                components={listComponents}
                className={classNames(
                    props.className,
                    'scrollbar-stable w-full h-full bg-gray-50 transition-colors shrink-0 flex overflow-x-hidden mb-4 border-t-none',
                    {
                    }
                )}

                fixedItemHeight={260}

                totalCount={props.loans.length}
                increaseViewportBy={200}
                itemContent={index => <Draggable
                    isDragDisabled={!props.isDragEnabled}
                    key={props.loans[index].id}
                    draggableId={props.loans[index].id}
                    index={index}>
                    {(provided, draggableSnapshot) => (<div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            ...getItemSx(provided.draggableProps.style),
                        }}
                        className='loan-card-container py-2 w-full w-full max-h-64 relative'

                    >
                        <NextLinkComposed
                            className='w-full'
                            to={{ pathname: Route.SINGLE_LOAN, query: { loanId: props.loans[index].id } }}
                        >
                            <LoanCard
                                onPin={props.onPinLoan}
                                highlightText={props.filterQuery}
                                loan={props.loans[index]}
                                onSendMessageClick={props.onSendMessageClick}
                            />
                        </NextLinkComposed>
                    </div>)}
                </Draggable>}


            />}
            <div
                style={{ opacity: props.isLoading ? 1 : 0 }}
                className='loan-card-container'>
                <LoansKanbanCardSkelton />
            </div>
            {provided.placeholder}
        </div>)}
    </Droppable>);
};
