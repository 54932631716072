import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { Route } from "src/constants/routing";
import { Loan } from "src/types/loan";

import { NextLinkComposed } from '../common/next-link-composed';
import { LoanCard } from "../loans/loan-card";

interface LoanKanbanSearchResultsProps {
    loans: Loan[];
    query: string;
    onPinLoan: (loanId: string) => void;
    onSendMessageClick: (loanId: string) => void;
}
export const LoanKanbanSearchResults = (props: LoanKanbanSearchResultsProps) => {
    if (props.loans.length === 0) return (
        <Stack items="center" justify='center' className='flex-1' space="md">
            <Icon name="BigExclamation" width={64} height={64} className="shrink-0 text-black-20" />
            <Text size="sm">
                No results match your search
            </Text>
        </Stack>
    );

    return <Stack row space="sm" className='flex-wrap overflow-y-scroll scrollbar-stable h-full'>
        {props.loans.map(loan => <NextLinkComposed
            key={loan.id}
            className='w-full max-w-[356px] max-h-64 h-full'
            to={{ pathname: Route.SINGLE_LOAN, query: { loanId: loan.id } }}
        >
            <LoanCard
                onPin={props.onPinLoan}
                highlightText={props.query}
                loan={loan}
                onSendMessageClick={props.onSendMessageClick}
            />
        </NextLinkComposed>)}
    </Stack>
}