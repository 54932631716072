import { j as a } from "./jsx-runtime-D0AQ1Mn7.js";
import { c as e } from "./utils-CJ9afRe1.js";
import "./Accordion-6RJGoeje.js";
import "./Avatar-DNKFm3wh.js";
import "./Badge-QBVLNL8O.js";
import "./Button-BOVHAiPs.js";
import "./DropdownMenu-BmUInubt.js";
import "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-BtfPjSeJ.js";
import { T as n } from "./Text-B4fIcnEL.js";
import "./Tooltip-ChPGW92B.js";
import { S as p } from "./Stack-BZqOy47V.js";
import "./Switch-D2_ymNuk.js";
import "./Toast-D1jXuM_f.js";
import "react";
import "./Toggle-BDJtD566.js";
const o = ({ children: r, className: t, col: i }) => /* @__PURE__ */ a.jsx(
  "li",
  {
    className: e(
      "border-b border-gray-card-border last:border-0 mt-8 first:mt-0 pb-1",
      t
    ),
    children: /* @__PURE__ */ a.jsx(p, { row: !i, className: "justify-between", children: r })
  }
);
o.displayName = "LoanCardItem";
const m = ({ children: r, className: t }) => /* @__PURE__ */ a.jsx(n, { size: "sm", className: e("text-gray-neutral-70", t), children: r });
m.displayName = "LoanCardItemTitle";
const s = ({ children: r, className: t }) => /* @__PURE__ */ a.jsx("div", { className: e(t), children: r });
s.displayName = "LoanCardItemValue";
const d = ({ className: r, children: t }) => /* @__PURE__ */ a.jsx("ul", { className: e("bg-white-neutral rounded-md p-4", r), children: t }), S = Object.assign(d, {
  Item: o,
  Title: m,
  Value: s
});
export {
  S as L
};
