import Icon from 'components/dist/atoms/Icon';
import { LoanReviewStatus } from "src/backend";
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip";
import { getReviewStatusTooltip } from "src/utils/loan/get-review-status-tooltip";

import { LoanReviewIconStyles } from "./loan-review-icon.styles";

interface LoanReviewIconProps {
    status: LoanReviewStatus;
}

export const LoanReviewIcon = (props: LoanReviewIconProps) => (<MysherpasTooltip
    title={getReviewStatusTooltip(props.status)}>
    <LoanReviewIconStyles.Wrapper
        data-status={props.status}
        className='loan-ready-for-review-icon-wrapper' >
        {props.status === 'IN_PROGRESS' && <Icon name="Origination" width={20} height={20} strokeWidth={1.5} />}
        {props.status === 'READY_FOR_REVIEW_PENDING' && <Icon name="Clock" width={20} height={20} />}
        {['REVIEW_COMPLETE_ACCEPTED', 'CLOSING'].includes(props.status) && <Icon name="Check" width={20} height={20} />}
        {props.status === 'READY_FOR_REVIEW_REVIEW' && <Icon name="PageEdit" width={20} height={20} />}
        {props.status === 'READY_FOR_UNDERWRITING' && <Icon name="GoogleDocs" width={20} height={20} />}
        {props.status === 'ARCHIVE' && <Icon name="Archive" width={20} height={20} />}
        {props.status === 'LEAD' && <Icon name="Filter" width={20} height={20} />}
        {props.status === 'IN_SERVICE' && <Icon name="MoneySquare" width={20} height={20} />}
        {props.status === 'IN_DEFAULT' && <Icon name="InfoEmpty" width={20} height={20} />}
        {props.status === 'WITHDRAWN' && <Icon name="ShoppingBagMinus" width={20} height={20} />}
        {props.status === 'REVIEW_COMPLETE_CONDITIONAL' && <Icon name="WarningCircle" width={20} height={20} />}
        {['REVIEW_COMPLETE_REJECTED', 'HOLD'].includes(props.status) && <Icon name="Minus" width={20} height={20} />}
    </LoanReviewIconStyles.Wrapper>
</MysherpasTooltip>)