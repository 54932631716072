import Add from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from 'components/dist/atoms/Button';
import { CustomizedTable } from 'components/dist/organisms/CustomizedTable';
import { MenuButton } from 'src/components/common/menu-button';
import { NextLinkComposed } from 'src/components/common/next-link-composed';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import {
  LoanListViewFixedColumns,
  LoanStageCategory,
  QUERY_PARAM_PHASE_CATEGORY,
} from 'src/constants/loan';
import { Route } from "src/constants/routing";
import { Permission } from 'src/constants/user';
import { Table2ColumnsIcon } from 'src/icons/table-2-columns-icon';
import { ViewGridIcon } from 'src/icons/view-grid-icon';
import { LoanDisplayView } from 'src/types/loan';

import { LoansKanbanFilters } from '../loans-kanban-filters';
import { useLoanFilters } from './loans-filters.hook';
import { LoanFiltersStyles } from './loans-filters.styles';

interface LoansFiltersProps {
  lenderId: string;
}

export const LoansFilters = (props: LoansFiltersProps) => {
  const {
    displayView,
    sortBy,
    sortItems,
    onSortByClick,
    onLoansDisplayViewChange,
    isDropdownOpen,
    dropdownAnchorEl,
    isLeadPhase,
    onDropdownClose,
    selectedHeaders,
    toggleHeader,
    allHeaders,
    isLoggedInUserALender,
    onReset
  } = useLoanFilters();

  return (
    <>
      <LoanFiltersStyles.Container className={`${!isLoggedInUserALender ? "hidden sm:flex" : ""} flex gap-2 flex-1 bg-white`}>
        <div className='flex w-full gap-2 justify-between flex-grow'>
          <div className='flex-1' >
            <LoanRoleGuard permission={Permission.CrudLoan}>
              <Button
                size="sm"
                asChild
                className='gap-2 hidden sm:inline-flex truncate'>
                <NextLinkComposed
                  to={{
                    pathname: Route.CREATE_LOAN,
                    query: {
                      ...(isLeadPhase && {
                        [QUERY_PARAM_PHASE_CATEGORY]: LoanStageCategory.LEAD,
                      }),
                    },
                  }}
                >
                  <Add fontSize="small" /> Start {isLeadPhase ? 'Lead' : 'Loan'}
                </NextLinkComposed>
              </Button>
            </LoanRoleGuard>
          </div>
          {isLoggedInUserALender && <div className='hidden sm:block'>
            <LoansKanbanFilters
              lenderId={props.lenderId} />
          </div>}
          {displayView === LoanDisplayView.list && <CustomizedTable.Trigger
            className="p-1 truncate"
            allHeaders={allHeaders}
            selectedHeaders={selectedHeaders}
            toggleHeader={toggleHeader}
            fixedHeaders={LoanListViewFixedColumns}
            onReset={onReset}
          />}
        </div>
        <div className='hidden sm:inline-flex flex gap-4 justify-between w-full md:w-auto'>
          <LoanFiltersStyles.ViewButtonGroup
            size="small"
            disableElevation
            aria-label="Disabled elevation buttons"
          >
            <MenuButton
              className='truncate'
              onClick={() => onLoansDisplayViewChange(LoanDisplayView.grid)}
              data-active={displayView === LoanDisplayView.grid}
              startIcon={<ViewGridIcon />}
            >
              Card view
            </MenuButton>
            <MenuButton
              className='truncate'
              onClick={() => onLoansDisplayViewChange(LoanDisplayView.list)}
              data-active={displayView === LoanDisplayView.list}
              startIcon={<Table2ColumnsIcon />}
            >
              Table view
            </MenuButton>
          </LoanFiltersStyles.ViewButtonGroup>

        </div>
      </LoanFiltersStyles.Container>
      <Menu
        onClose={onDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={dropdownAnchorEl}
        open={Boolean(isDropdownOpen)}
      >
        {sortItems.map((item) => (
          <MenuItem
            selected={item.value === sortBy}
            key={item.value}
            onClick={() => onSortByClick(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

